import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import _ from 'lodash';
import { array, object } from 'prop-types';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { StoryblokComponent, storyblokEditable } from 'gatsby-source-storyblok';
import throttle from 'lodash/throttle';
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image';
import { getGatsbyImage } from 'gatsby-plugin-storyblok-image';
import { btnLgPrimaryThinOrangeStyles, btnSmPrimaryThinOrangeStyles } from '../../../styles/Web3.0/buttons';
import { spacerSStyles, spacerXsStyles } from '../../../styles/Web3.0/structureSpacing';
import {
  bodyLinkStyles,
  bodyLRegularUtopiaStyles,
  bodyMBoldBrownStyles,
  bodyMRegularBrownStyles,
  bodyMRegularUtopiaStyles,
  bodySBoldBrownStyles,
  bodySRegularBrownStyles,
  bodySRegularUtopiaStyles,
  bodyXxlBoldBrownStyles,
  bodyXxsRegularBrownStyles,
  eyebrowMStyles,
} from '../../../styles/Web3.0/typography';
import { bpWidth, colors, sizes } from '../../../styles/Web3.0/variables';
import FeaturedImagesRow from './FeaturedImagesRow.jsx';
import LimitedEditionTag from './LimitedEditionTag';
import { currencyFormatter } from '../../../utils/currencyFormatter';
import LooxRating from './LooxRating.jsx';
import { useStoryblokStories } from '../../../hooks';
import { renderRichTextReact } from '../../../utils/storyblokRichText';
import { useRootState } from '../../../context/root-state.context';
import { findNestedObject } from '../../../utils/helper';
import { defaultStoryblokStoryParams } from '../../../common/constants/storyblok.constant';
import { useAppState } from '../../../context/app-state.context';
import { useViewport } from '../../../context/viewport.context';

const ShopProductListCol = styled(Col)`
  .space-separator {
    &:first-child {
      padding-right: 12px;
    }

    &:last-child {
      padding-left: 12px;
    }

    @media (${bpWidth.desktopSm}) {
      &:first-child {
        padding-right: 10px;
      }

      &:last-child {
        padding-left: 10px;
      }
    }

    @media (${bpWidth.tablet}) {
      &:first-child {
        padding-right: 8px;
      }

      &:last-child {
        padding-left: 8px;
      }
    }

    @media (${bpWidth.mobile}) {
      &:first-child {
        padding-right: 5px;
      }

      &:last-child {
        padding-left: 5px;
      }
    }
  }
`;

const ProductNamesPricesRow = styled(Row)`
  display: none;

  &.fixed {
    display: flex;
    animation-duration: 4s;
    background-color: ${colors.beige100};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
`;

const ProductFeaturesCol = styled(Col)`
  border-top: 1px solid ${colors.gray100};

  h3.productSpecificationsHeading {
    ${eyebrowMStyles};
    ${spacerSStyles};
    color: ${colors.gray200};
    padding-bottom: 20px;
  }

  @media (${bpWidth.desktopSm}) {
    h3.productSpecificationsHeading {
      ${spacerXsStyles};
      padding-bottom: 20px;
    }
  }
`;

const LogoCol = styled(Col)`
  &:first-child {
    padding-right: 12px;
  }

  &:last-child {
    padding-left: 12px;
  }

  img {
    max-width: 164px;
    margin-bottom: 20px;
  }

  @media (${bpWidth.desktopSm}) {
    img {
      max-width: 115px;
    }
  }

  @media (${bpWidth.tablet}) {
    .shop-product-logo {
      text-align: center;
    }
  }

  @media (${bpWidth.mobile}) {
    &:first-child {
      padding-right: 5px;
    }

    &:last-child {
      padding-left: 5px;
    }
  }
`;

const ProductInfoRow = styled(Row)`
  border-bottom: 1px solid ${colors.gray100};
  padding-bottom: 40px;

  &:last-child {
    border-bottom: none;
  }
`;

const ProductPricesCtaRow = styled(Row)`
  margin: 30px 0 80px 0;

  button {
    ${btnLgPrimaryThinOrangeStyles};
    font-size: 11px;
    cursor: pointer;
  }

  h4 {
    ${bodyXxlBoldBrownStyles}
  }

  p,
  a {
    ${bodyMRegularUtopiaStyles}
  }

  span {
    ${bodySRegularBrownStyles}
  }

  h4,
  p,
  span {
    color: ${colors.navy600};
  }

  @media (${bpWidth.desktopSm}) {
    margin: 20px 0 70px 0;

    button {
      ${btnSmPrimaryThinOrangeStyles};
      width: 130px;
      font-size: 12px;
      line-height: 1.2;
      margin-top: 15px;
    }

    h4 {
      ${bodyMBoldBrownStyles};
    }
    span {
      ${bodyXxsRegularBrownStyles};
    }

    h4,
    span {
      color: ${colors.navy600};
    }
  }
  @media (${bpWidth.tablet}) {
    margin: 20px 0 35px 0;
    text-align: center;

    button {
      width: 96px;
      margin-top: 15px;
    }

    h4 {
      margin-top: 10px;
    }
  }

  @media (${bpWidth.mobile}) {
    button {
      text-overflow: ellipsis;
      padding-left: 5px;
      margin-top: 15px;
      font-size: 11px;
      line-height: 1.2;
    }

    p {
      ${bodySRegularUtopiaStyles};
      color: ${colors.navy600};
    }
    a {
      ${bodySRegularUtopiaStyles};
    }
  }

  .loox-rating {
    padding-bottom: 0;
  }
`;

const ProductPricesCtaRowFixed = styled(ProductPricesCtaRow)`
  margin: 40px 0 20px 0;

  .logo,
  .thumbnail {
    width: 130px;
  }

  .thumbnail {
    border-radius: 15px;
    margin-right: 10px;
    height: 130px;
  }

  .leftColFixed,
  .rightColFixed {
    max-width: fit-content;
  }

  .rightColFixed {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .fixedCopy {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      a {
        padding-left: 12px;
      }
      a.affirm-modal-trigger {
        ${bodyLinkStyles};
        padding: 0;
      }

      .loox-rating {
        ${bodySRegularBrownStyles};
        color: ${colors.cerulean400};

        span {
          ${bodySRegularBrownStyles};
          color: ${colors.cerulean400};
        }
      }
    }
  }

  .leftColFixed {
    .logo {
      display: none;
      margin-bottom: 20px;
    }
  }

  @media (${bpWidth.desktopSm}) {
    .logo,
    .thumbnail {
      width: 110px;
    }

    .thumbnail {
      height: 110px;
    }

    .rightColFixed {
      flex-grow: 1;
    }
  }

  @media only screen and (max-width: 846px) and (min-width: 769px) {
    h4 {
      ${bodySBoldBrownStyles};
    }

    button {
      width: 75px;
    }

    .thumbnail {
      margin-right: 4px;
    }
  }

  @media (${bpWidth.tablet}) {
    flex-direction: column;

    .thumbnail {
      margin-right: 0;
    }

    .leftColFixed,
    .rightColFixed {
      max-width: 110px;
      width: 100%;
    }

    .rightColFixed {
      .logo {
        display: none;
      }

      .fixedCopy {
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
          padding: 0;
        }

        .loox-rating {
          ${bodySRegularBrownStyles};
          color: ${colors.cerulean400};

          span {
            ${bodySRegularBrownStyles};
            color: ${colors.cerulean400};
          }
        }
      }
    }

    .leftColFixed {
      display: flex;
      flex-direction: column;

      .logo {
        display: unset;
      }
    }
  }

  @media (${bpWidth.mobile}) {
    .logo,
    .thumbnail {
      width: 90px;
      height: auto;
    }
    .leftColFixed,
    .rightColFixed {
      max-width: 100%;
      width: 100%;
    }
  }
`;

const ProductPriceCol = styled(Col)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin-bottom: 20px;
  margin-top: 0;

  .loox-rating {
    ${bodyMRegularBrownStyles};
    color: ${colors.cerulean400};
    padding-top: 5px;

    span {
      ${bodyMRegularBrownStyles};
      color: ${colors.cerulean400};
    }
  }

  a.affirm-modal-trigger {
    ${bodyLinkStyles};
  }

  @media (${bpWidth.desktopSm}) {
    margin-bottom: 10px;
    margin-top: 0;

    .loox-rating {
      ${bodySRegularBrownStyles};
      color: ${colors.cerulean400};

      span {
        ${bodySRegularBrownStyles};
        color: ${colors.cerulean400};
      }
    }
  }
  @media (${bpWidth.tablet}) {
    flex-direction: column;

    button {
      margin-top: 10px;
    }
  }
`;

const ProductDescriptionType = styled.div`
  padding-top: 0;

  span {
    ${bodyLRegularUtopiaStyles};
    display: flex;
    flex-direction: column;
  }

  span u,
  p u {
    text-decoration: unset;
    vertical-align: super;
    font-size: smaller;
  }

  p.flexColumn {
    display: flex;
    flex-direction: row;

    span {
      padding-left: 3px;
    }
  }

  @media (${bpWidth.desktopSm}) {
    span {
      ${bodySRegularUtopiaStyles};
    }
  }
`;

const ProductColorVariants = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;

  &:last-child {
    padding-bottom: 0;
  }

  div.boardwalkBeige,
  div.red,
  div.signalRed,
  div.sparkCitron,
  div.twilightBlue,
  div.thunderGray,
  div.shadowBlack,
  div.rapidBlue,
  div.espressoBrown {
    border-radius: 50%;
    height: 20px;
    margin-right: 15px;
    width: 20px;
  }

  div.boardwalkBeige {
    background-color: ${colors.boardwalkBeige};
  }

  div.red {
    background-color: ${colors.red};
  }

  div.signalRed {
    background-color: ${colors.signalRed};
  }

  div.sparkCitron {
    background-color: ${colors.sparkCitron};
  }

  div.twilightBlue {
    background-color: ${colors.twilightBlue};
  }

  div.thunderGray {
    background-color: ${colors.thunderGray};
  }

  div.shadowBlack {
    background-color: ${colors.black};
  }

  div.rapidBlue {
    background-color: ${colors.rapidBlue};
  }

  div.espressoBrown {
    background-color: ${colors.espressoBrown};
  }

  div.colorCopy {
    display: flex;
    align-items: center;

    div {
      margin-left: 16px;
    }
  }

  &.miniLe {
    align-items: flex-start;

    div.shadowBlack {
      align-self: center;
    }
  }

  @media (${bpWidth.desktopSm}) {
    padding-bottom: 10px;

    div.boardwalkBeige,
    div.red,
    div.signalRed,
    div.sparkCitron,
    div.twilightBlue,
    div.thunderGray,
    div.shadowBlack,
    div.rapidBlue,
    div.espressoBrown {
      height: 15px;
      margin-right: 10px;
      width: 15px;
    }

    div.colorCopy {
      div {
        margin-left: 11px;
      }
    }
  }

  @media only screen and (max-width: 532px) {
    &.miniLe {
      div.shadowBlack {
        align-self: flex-start;
      }

      div.colorCopy {
        margin-top: -4px;
      }
    }

    div.colorCopy {
      display: flex;
      flex-direction: column;
      align-items: baseline;

      div {
        margin-left: 0;
      }
    }
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
`;

// ensure product specs are sorted by handle according to a predefined order. bugfix for WEBDEV-767.
const productSpecificationListOrdering = {
  gitaplus: 1,
  gitamini: 2,
};

// Products' prices & CTAs
const priceRange = (maxPrice, minPrice) => {
  if (maxPrice === minPrice) {
    const formattedPrice = currencyFormatter(maxPrice, { minimumFractionDigits: 0 });
    return <h4>{formattedPrice}</h4>;
  }

  const formattedMinPrice = currencyFormatter(minPrice, { minimumFractionDigits: 0 });
  const formattedMaxPrice = currencyFormatter(maxPrice, { minimumFractionDigits: 0 });

  return (
    <h4>
      {formattedMinPrice} - {formattedMaxPrice}
    </h4>
  );
};

const ProductNamesPrice = React.memo(({ shopifyProduct, mainProducts, productAffirms }) => {
  const mainProduct = mainProducts.find((item) => item.handle === shopifyProduct.handle);

  if (!mainProduct) {
    return undefined;
  }

  const productLink = `/shop/${shopifyProduct.handle}`;
  const slug = _.kebabCase(shopifyProduct.handle);
  const productDescription = (mainProduct.variants?.filter((variant) => variant.limitedEdtion !== true) || [])?.[0]
    ?.description;

  const affirmVariantComponent = productAffirms[`homepage-affirm-${slug}`];
  if (affirmVariantComponent) {
    setTimeout(() => affirmVariantComponent.render(`.affirm-${slug}`));
  }

  return (
    <ProductPricesCtaRow>
      <ProductPriceCol xs={8} md={7} lg={8}>
        <div>
          {priceRange(
            shopifyProduct.priceRangeV2.maxVariantPrice.amount,
            shopifyProduct.priceRangeV2.minVariantPrice.amount
          )}
          <p className={`affirm-${slug}`} />
          {/* Loox rating */}
          {!mainProduct.hideRating && (
            <LooxRating link={`${productLink}#productReviews`} shopifyId={shopifyProduct.shopifyId} />
          )}
        </div>
        <Link to={productLink}>
          <button type="button">SHOP</button>
        </Link>
      </ProductPriceCol>
      <Col xs={8} md={8} lg={7}>
        {renderRichTextReact(productDescription)}
      </Col>
    </ProductPricesCtaRow>
  );
});

ProductNamesPrice.propTypes = {
  shopifyProduct: object.isRequired,
  mainProducts: array.isRequired,
  productAffirms: object.isRequired,
};

const FixedProductNamesPrice = React.memo(({ shopifyProduct, mainProducts, productAffirms }) => {
  const { viewWidth } = useViewport();
  const mainProduct = mainProducts.find((item) => item.handle === shopifyProduct.handle);

  if (!mainProduct) {
    return undefined;
  }

  const productLink = `/shop/${shopifyProduct.handle}`;
  const slug = _.kebabCase(shopifyProduct.handle);
  const [logo] = mainProduct.logo;

  let logoIconUrl =
    logo?.assetLg?.filename || logo?.assetMd?.filename || logo?.assetSm?.filename || logo?.assetXs?.filename;

  if (viewWidth < sizes.containerMinWidth.tablet) {
    if (logo?.assetXs?.filename) {
      logoIconUrl = logo?.assetXs.filename;
    }
  } else if (viewWidth < sizes.containerMinWidth.desktopSm) {
    if (logo?.assetSm?.filename) {
      logoIconUrl = logo?.assetSm.filename;
    }
  } else if (viewWidth < sizes.containerMinWidth.desktopLg) {
    if (logo?.assetMd?.filename) {
      logoIconUrl = logo?.assetMd.filename;
    }
  }

  const [featureImage] = mainProduct.featureImage;
  let featureImageUrl =
    featureImage?.assetLg?.filename ||
    featureImage?.assetMd?.filename ||
    featureImage?.assetSm?.filename ||
    featureImage?.assetXs?.filename;

  let featureImageWidth = featureImage.widthLg || featureImage.widthMd || featureImage.widthSm || featureImage.widthXs;
  let featureImageHeight =
    featureImage.heightLg || featureImage.heightMd || featureImage.heightSm || featureImage.heightXs;

  if (viewWidth < sizes.containerMinWidth.tablet) {
    if (featureImage?.assetXs?.filename) {
      featureImageUrl = featureImage?.assetXs.filename;
    }
    if (featureImage.widthXs) {
      featureImageWidth = featureImage.widthXs;
    }
    if (featureImage.heightXs) {
      featureImageHeight = featureImage.heightXs;
    }
  } else if (viewWidth < sizes.containerMinWidth.desktopSm) {
    if (featureImage?.assetSm?.filename) {
      featureImageUrl = featureImage?.assetSm.filename;
    }
    if (featureImage.heightSm) {
      featureImageWidth = featureImage.heightSm;
    }
    if (featureImage.heightSm) {
      featureImageHeight = featureImage.heightSm;
    }
  } else if (viewWidth < sizes.containerMinWidth.desktopLg) {
    if (featureImage?.assetMd?.filename) {
      featureImageUrl = featureImage?.assetMd.filename;
    }
    if (featureImage.heightMd) {
      featureImageWidth = featureImage.heightMd;
    }
    if (featureImage.heightMd) {
      featureImageHeight = featureImage.heightMd;
    }
  }

  let featureImageData;

  if (featureImageUrl) {
    const featureImageArtDirected = [];
    let options = {
      layout: featureImage.layout,
      quality: featureImage.quality,
    };

    if (featureImage.assetXs?.filename) {
      options = {
        ...options,
        width: 414,
      };
      featureImageArtDirected.push({
        media: `(${bpWidth.mobile})`,
        image: getGatsbyImage(featureImage.assetXs.filename, options),
      });
    }

    if (featureImage.assetSm?.filename) {
      options = {
        ...options,
        width: 768,
      };
      featureImageArtDirected.push({
        media: `(${bpWidth.tablet})`,
        image: getGatsbyImage(featureImage.assetSm.filename, options),
      });
    }

    if (featureImage.assetMd?.filename) {
      options = {
        ...options,
        width: 1280,
      };
      featureImageArtDirected.push({
        media: `(${bpWidth.desktopSm})`,
        image: getGatsbyImage(featureImage.assetMd.filename, options),
      });
    }

    options = {
      ...options,
      width: 1920,
    };
    featureImageData = withArtDirection(getGatsbyImage(featureImageUrl, options), featureImageArtDirected);
  }

  const affirmVariantComponent = productAffirms[`homepage-affirm-${slug}`];
  if (affirmVariantComponent) {
    setTimeout(() => affirmVariantComponent.render(`.affirm-fixed-${slug}`));
  }

  return (
    <ProductPricesCtaRowFixed>
      <div className="leftColFixed">
        {!!logoIconUrl && (
          <Link to={productLink}>
            <img src={logoIconUrl} alt="logo-icon" className="logo" />
          </Link>
        )}
        {!!featureImageData && (
          <Link to={productLink}>
            <StyledGatsbyImage
              image={featureImageData}
              alt="feature-image"
              loading={featureImage.loadType || 'lazy'}
              width={featureImageWidth}
              height={featureImageHeight}
              className="thumbnail"
            />
          </Link>
        )}
      </div>

      <div className="rightColFixed">
        {!!logoIconUrl && (
          <Link to={productLink}>
            <img src={logoIconUrl} alt="logo-icon" className="logo" />
          </Link>
        )}
        <div className="fixedCopy">
          <div>
            {priceRange(
              shopifyProduct.priceRangeV2.maxVariantPrice.amount,
              shopifyProduct.priceRangeV2.minVariantPrice.amount
            )}
            <div className="hidden md:flex flex-col">
              <p className={`affirm-fixed-${slug}`} />

              {/* Loox rating */}
              {!mainProduct.hideRating && (
                <LooxRating link={`${productLink}#productReviews`} shopifyId={shopifyProduct.shopifyId} />
              )}
            </div>
          </div>
          <Link to={productLink}>
            <button type="button">SHOP</button>
          </Link>
        </div>
      </div>
    </ProductPricesCtaRowFixed>
  );
});

FixedProductNamesPrice.propTypes = {
  shopifyProduct: object.isRequired,
  mainProducts: array.isRequired,
  productAffirms: object.isRequired,
};

const ShopProductList = ({ blok }) => {
  const { allShopifyProducts, productColors, productAffirms } = useRootState();
  const { hamburgerMenuIsOpen } = useAppState();

  // eslint-disable-next-line no-unused-vars
  const [promoBannerTop, setPromoBannerTop] = useState('0px');
  const [scrolledPastShopHeader, setScrolledPastShopHeader] = useState(false);
  const [mainProducts, setMainProducts] = useState([]);
  const [productSpecificationList, setProductSpecificationList] = useState([]);
  const [productSpecificationNameList, setProductSpecificationNameList] = useState([]);

  const productFeaturesRef = useRef(null);
  const fixedProductNamesPricesRef = useRef(null);

  const productStories = useStoryblokStories({
    ...defaultStoryblokStoryParams,
    by_uuids: (blok?.shopProductList || []).join(','),
  });

  // Data
  useEffect(() => {
    const products = (productStories?.data?.stories || []).map((story) =>
      findNestedObject(story, 'component', 'shopProduct')
    );
    setMainProducts(products);

    /** @type {any[][]} */
    const specificationList = products
      .sort((a, b) => productSpecificationListOrdering[a.handle] - productSpecificationListOrdering[b.handle])
      .map((p) => p.specifications || []);
    setProductSpecificationList(specificationList);

    /** @type {string[][]} */
    let specificationNameList = specificationList.map((s) => s.map((item) => item.name));
    specificationNameList = specificationNameList.sort((a, b) => b.length - a.length);

    const [firstSpecificationNameList = []] = specificationNameList;
    setProductSpecificationNameList(firstSpecificationNameList);
  }, [blok, productStories]);

  // Product logo
  const productLogo = useCallback(
    (productHandle, productLink) => {
      const foundProduct = mainProducts.find((p) => p.handle === productHandle);
      return foundProduct?.logo?.length > 0 ? (
        <Link to={productLink}>
          <StoryblokComponent blok={foundProduct.logo[0]} />
        </Link>
      ) : undefined;
    },
    [mainProducts]
  );

  // Products' colors
  const getProductColors = useCallback(
    (shopifyProductColors, productHandle) => {
      const mainProduct = mainProducts.find((p) => p.handle === productHandle);
      if (!mainProduct) {
        return undefined;
      }

      return (
        <ProductDescriptionType>
          {shopifyProductColors.map((color) => {
            const productColor = productColors.find((pc) => pc.name === color);
            if (!productColor) {
              return undefined;
            }
            const variant = (mainProduct.variants || []).find((v) => v.color === productColor.value && v.enable);
            if (!variant) {
              return undefined;
            }

            return (
              <ProductColorVariants key={productColor.id} className={variant.limitedEdtion ? 'miniLe' : undefined}>
                <div className={_.camelCase(productColor.name)} style={{ backgroundColor: productColor.value }} />
                <div className="colorCopy">
                  <span>{productColor.name}</span>
                  {variant.limitedEdtion ? <LimitedEditionTag /> : undefined}
                </div>
              </ProductColorVariants>
            );
          })}
        </ProductDescriptionType>
      );
    },
    [mainProducts]
  );

  useEffect(() => {
    // Freeze product header
    if (fixedProductNamesPricesRef.current) {
      const productNamesPricesElement = fixedProductNamesPricesRef.current;
      const promoBanner = document.getElementById('activePromoBanner');

      if (!hamburgerMenuIsOpen && scrolledPastShopHeader) {
        productNamesPricesElement.classList.add('fixed');
      } else if (!scrolledPastShopHeader && productNamesPricesElement.classList.contains('fixed')) {
        productNamesPricesElement.classList.remove('fixed');
      }

      // Shift promo banner above the product header, if the promo banner is active
      if (productNamesPricesElement.classList.contains('fixed') && promoBanner) {
        setPromoBannerTop(promoBanner.offsetHeight.toString().concat('px'));
      } else {
        setPromoBannerTop('0px');
      }
    }
  }, [fixedProductNamesPricesRef.current, scrolledPastShopHeader, hamburgerMenuIsOpen]);

  // Move product header on scroll
  const moveShopHeader = () => {
    const currentScrollPosition = window.scrollY;
    const triggerLocationPoint = productFeaturesRef.current?.offsetTop || 0;

    if (currentScrollPosition > triggerLocationPoint) {
      setScrolledPastShopHeader(true);
    } else {
      setScrolledPastShopHeader(false);
    }
  };

  const throttledMoveCheckoutHeader = throttle(moveShopHeader, 100);

  useEffect(() => {
    // Move fixed product header on scroll
    window.addEventListener('scroll', throttledMoveCheckoutHeader);

    return () => {
      window.removeEventListener('scroll', throttledMoveCheckoutHeader);
    };
  }, [productFeaturesRef.current]);

  return (
    <ShopProductListCol xs={8} md={10} lg={12} {...storyblokEditable(blok)}>
      {/* Products' logos */}
      <Row>
        {allShopifyProducts.map((product) => (
          <LogoCol xs={4} md={5} lg={6} key={`logo_${product.handle}`}>
            {productLogo(product.handle, '/shop/' + product.handle)}
          </LogoCol>
        ))}
      </Row>

      {/* Products' featured images */}
      <FeaturedImagesRow shopifyMainProducts={allShopifyProducts} mainProducts={mainProducts} />

      {/* Products' price */}
      <Row>
        <Col xs={8} md={10} lg={12}>
          <Row>
            {allShopifyProducts.map((product) => (
              <Col className="space-separator" xs={4} md={5} lg={6} key={`price_${product.handle}`}>
                <ProductNamesPrice
                  shopifyProduct={product}
                  mainProducts={mainProducts}
                  productAffirms={productAffirms}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <ProductNamesPricesRow ref={fixedProductNamesPricesRef}>
        <Col xsOffset={1} xs={6} md={8} lg={10}>
          <Row>
            {allShopifyProducts.map((product) => (
              <Col className="space-separator" xs={4} md={5} lg={6} key={`price_${product.handle}`}>
                <FixedProductNamesPrice
                  shopifyProduct={product}
                  mainProducts={mainProducts}
                  productAffirms={productAffirms}
                />
              </Col>
            ))}
          </Row>
        </Col>
      </ProductNamesPricesRow>

      {/* Products' features */}
      <Row ref={productFeaturesRef}>
        <ProductFeaturesCol xsOffset={0.5} mdOffset={0} xs={7} md={10} lg={12}>
          {/* Product's colors */}
          <h3 className="productSpecificationsHeading">Colors</h3>
          <ProductInfoRow>
            {allShopifyProducts.map((product) => (
              <Col className="space-separator" xs={4} md={5} lg={6} key={`color_${product.handle}`}>
                {getProductColors(product.options[0].values, product.handle)}
              </Col>
            ))}
          </ProductInfoRow>

          {/* Product's categories */}
          {productSpecificationNameList.map((specName, nameIndex) => {
            return (
              <React.Fragment key={nameIndex}>
                <h3 className="productSpecificationsHeading">{renderRichTextReact(specName)}</h3>
                <ProductInfoRow>
                  {allShopifyProducts.map((product, productIndex) => {
                    const foundSpec = productSpecificationList?.[productIndex]?.[nameIndex];
                    if (!foundSpec) {
                      return undefined;
                    }

                    return (
                      <Col className="space-separator" xs={4} md={5} lg={6} key={foundSpec._uid}>
                        <ProductDescriptionType>
                          <span>{renderRichTextReact(foundSpec.value)}</span>
                        </ProductDescriptionType>
                      </Col>
                    );
                  })}
                </ProductInfoRow>
              </React.Fragment>
            );
          })}
        </ProductFeaturesCol>
      </Row>
    </ShopProductListCol>
  );
};

ShopProductList.propTypes = {
  blok: object.isRequired,
};

ShopProductList.defaultProps = {};

export default ShopProductList;
